import { ClaimStatusReason } from "@/types/project";
import { ClaimDetailsEditSection } from "./ClaimDetailsEditSection";

interface ClaimDetailsCollapsibleRightHalfProps {
  claimText: string;
  selectedReason: (ClaimStatusReason & { index: number; claimIndex: number }) | null;
  isEditing: boolean;
  isCitationsSectionVisible: boolean;
  citationsSection: React.ReactNode;
}

export const ClaimDetailsCollapsibleRightHalf = ({
  claimText,
  selectedReason,
  isEditing,
  isCitationsSectionVisible,
  citationsSection,
}: ClaimDetailsCollapsibleRightHalfProps) => {
  return (
    <div className="w-1/2 pl-2 space-y-0.5">
      {isEditing && (
        <ClaimDetailsEditSection
          claimText={claimText}
          selectedReason={selectedReason}
        />
      )}

      {isCitationsSectionVisible && (
        <div className="border border-gray-200 rounded-md py-6">
          <div className="mx-6 space-y-6 text-justify">{citationsSection}</div>
        </div>
      )}
    </div>
  );
};
