import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useViz } from "@/hooks";
import { useProjectStore } from "@/store";
import {
  ClaimAmendments,
  ClaimStatusReason,
  DocumentType,
  OfficeAction,
} from "@/types/project";
import { showBoldText } from "@/utils/parseHtml";
import { useEffect, useState } from "react";
import { DraftingButton } from "./DraftingButtons";

interface ClaimDetailsEditSectionProps {
  claimText: string;
  selectedReason: ClaimStatusReason & { index: number; claimIndex: number };
}

export const ClaimDetailsEditSection = ({
  claimText,
  selectedReason,
}: ClaimDetailsEditSectionProps) => {
  // Hooks
  const { updateCurrentProject, currentProject } = useProjectStore();
  const { generateClaimAmendments, generateClaimArgument } = useViz();

  // Local states
  const [generatingAmendments, setGeneratingAmendments] = useState(false);
  const [generatingArguments, setGeneratingArguments] = useState(false);

  const [argument, setArgument] = useState<string[]>(selectedReason.arguments || []);
  const [amendments, setAmendments] = useState<ClaimAmendments[]>(
    selectedReason.amendments || [],
  );

  useEffect(() => {
    setArgument(selectedReason.arguments || []);
    setAmendments(selectedReason.amendments || []);
  }, [selectedReason]);

  const updateStore = (newData: any, type: "amendments" | "arguments") => {
    // Update the document in the store
    const updatedDocs = currentProject.applicationDocuments.map((doc) => {
      if (doc.document_type === DocumentType.OFFICE_ACTION) {
        const officeAction = doc.document as OfficeAction;
        const updatedClaimDetails = officeAction.claimDetails.map(
          (claim, claimIndex) => {
            if (claimIndex === selectedReason.claimIndex) {
              return {
                ...claim,
                reasons: claim.reasons.map((reason, reasonIndex) => {
                  if (reasonIndex === selectedReason.index) {
                    return { ...reason, [type]: newData };
                  }
                  return reason;
                }),
              };
            }
            return claim;
          },
        );

        return {
          ...doc,
          document: { ...officeAction, claimDetails: updatedClaimDetails },
        };
      }
      return doc;
    });

    updateCurrentProject({
      applicationDocuments: updatedDocs,
      id: currentProject.id,
      name: currentProject.name,
    });
  };

  // Arguments and Amendments generation
  const handleGenerateAmendments = async () => {
    setGeneratingAmendments(true);
    try {
      const response = await generateClaimAmendments(claimText, selectedReason);
      if (response.success) {
        const topThreeAmendments = response.data.amendments
          .slice(0, 3)
          .map((amendment) => ({
            text: amendment.text,
            reason: amendment.reason,
          }));

        selectedReason.amendments = topThreeAmendments;
        updateStore(topThreeAmendments, "amendments");
        setAmendments(topThreeAmendments);
      }
    } finally {
      setGeneratingAmendments(false);
    }
  };

  const handleGenerateArguments = async () => {
    setGeneratingArguments(true);
    try {
      const response = await generateClaimArgument(claimText, selectedReason);
      if (response.success) {
        const newArguments = response.data.arguments;
        selectedReason.arguments = newArguments;
        updateStore(newArguments, "arguments");
        setArgument(selectedReason.arguments || []);
      }
    } finally {
      setGeneratingArguments(false);
    }
  };
  return (
    <div className="rounded-md">
      <div className="mx-4">
        <Tabs defaultValue="arguments">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="arguments">Arguments</TabsTrigger>
            <TabsTrigger value="amendments">Amendments</TabsTrigger>
          </TabsList>
          <TabsContent value="arguments">
            <div className="rounded-md pt-3 mb-4 bg-gray-50">
              <div className="flex items-center mx-4 mb-2">
                <h3 className="text-sm font-bold">Suggested Arguments</h3>
                <div className="ml-3">
                  <DraftingButton
                    onClick={handleGenerateArguments}
                    isGenerating={generatingArguments}
                    label={argument.length === 0 ? "Generate" : "Regenerate"}
                    disabled={generatingAmendments}
                  />
                </div>
              </div>
              {argument.length > 0 ? (
                <div className="mx-4 pb-4 space-y-3">
                  <div className="space-y-8">
                    {argument.map((text, index) => (
                      <div key={index} className="flex">
                        <span className="mr-2">•</span>
                        <p className="text-sm text-justify whitespace-pre-line flex-1">
                          {text}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="mx-4 pb-4 text-sm text-gray-500">
                  No arguments generated
                </div>
              )}
            </div>
          </TabsContent>
          <TabsContent value="amendments">
            <div className="rounded-md pt-3 mb-4 bg-gray-50">
              <div className="flex items-center mx-4 mb-2">
                <h3 className="text-sm font-bold">Suggested Amendments</h3>
                <div className="ml-3">
                  <DraftingButton
                    onClick={handleGenerateAmendments}
                    isGenerating={generatingAmendments}
                    label={amendments.length === 0 ? "Generate" : "Regenerate"}
                    disabled={generatingArguments}
                  />
                </div>
              </div>
              {amendments.length > 0 ? (
                <div className="mx-4 pb-4 space-y-3">
                  <div className="space-y-8">
                    {amendments.map((amendment, index) => (
                      <div key={index} className="flex">
                        <span className="mr-2">•</span>
                        <p className="text-sm text-justify whitespace-pre-line flex-1">
                          {showBoldText(amendment.text)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="mx-4 pb-4 mb-4 text-sm text-gray-500">
                  No amendments generated
                </div>
              )}
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};
