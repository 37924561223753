/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ImageContainer, ReferenceImage } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { LoadingButton } from "@/components/ui/loading-button";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useDataTable, useViz } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ElementType } from "@/types";
import { Cross2Icon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import CitationForm from "./CitationForm";
import { ImageUploadModal, ReferenceFigureSelectModal } from "./index";

interface AddCitationModalProps {
  open: boolean;
  handleClose: () => void;
  referenceId: string;
  elementType: ElementType;
  claimNumber: string;
  invalidityId: string;
  rowIndex: number;
}

/**
 * @description Prune invalidity charts by citation score
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const AddCitationModal: React.FC<AddCitationModalProps> = ({
  open,
  handleClose,
  referenceId,
  elementType,
  claimNumber,
  invalidityId,
  rowIndex,
}) => {
  const { addCitationFromPatentViewer } = useDataTable();
  const { addErrorMessage } = useAppStateStore();
  const { currentProjectId, currentProject } = useProjectStore();
  const { uploadImageToS3 } = useViz();

  const [isLoading, setIsLoading] = useState(false);

  // State for citation fields
  const [citationText, setCitationText] = useState<string>("");
  const [pageFrom, setPageFrom] = useState<number | null>(null);
  const [pageTo, setPageTo] = useState<number | null>(null);
  const [paragraph, setParagraph] = useState<number | null>(null);
  const [lineFrom, setLineFrom] = useState<number | null>(null);
  const [lineTo, setLineTo] = useState<number | null>(null);
  const [claimSection, setClaimSection] = useState<string | null>(null);
  const [columnFrom, setColumnFrom] = useState<number | null>(null);
  const [columnTo, setColumnTo] = useState<number | null>(null);

  // Validation state
  const [citationTextError, setCitationTextError] = useState<boolean>(false);
  const [citationLocationError, setCitationLocationError] = useState<boolean>(false);
  const [lineColumnError, setLineColumnError] = useState<boolean>(false);
  const [columnRangeError, setColumnRangeError] = useState<boolean>(false);
  const [lineRangeError, setLineRangeError] = useState<boolean>(false);
  const [pageRangeError, setPageRangeError] = useState<boolean>(false);

  // Add new state for image handling
  const [imageUploadModalOpen, setImageUploadModalOpen] = useState<boolean>(false);
  const [figureSelectModalOpen, setFigureSelectModalOpen] = useState<boolean>(false);

  const [referenceFiguresToAttach, setReferenceFiguresToAttach] = useState<string[]>(
    [],
  );

  useEffect(() => {
    resetModal();
  }, [open]);

  const resetModal = () => {
    setIsLoading(false);
    setCitationText("");
    setPageFrom(null);
    setPageTo(null);
    setParagraph(null);
    setLineFrom(null);
    setLineTo(null);
    setClaimSection(null);
    setColumnFrom(null);
    setColumnTo(null);
    setCitationLocationError(false);
    setLineColumnError(false);
    setColumnRangeError(false);
    setLineRangeError(false);
    setPageRangeError(false);
    setReferenceFiguresToAttach([]);
  };

  const onClose = () => {
    resetModal();
    handleClose();
  };

  const handleSave = async () => {
    setIsLoading(true);
    const trimmedCitationText = citationText.trim();

    // Fix page validation to handle NaN values
    const hasPageFrom = !isNaN(pageFrom);
    const hasPageTo = !isNaN(pageTo);

    if (
      (hasPageFrom && !hasPageTo) ||
      (!hasPageFrom && hasPageTo) ||
      (hasPageFrom && hasPageTo && pageTo < pageFrom)
    ) {
      setPageRangeError(true);
      setIsLoading(false);
      return;
    }

    // Add citation text validation
    if (!trimmedCitationText) {
      setCitationTextError(true);
      setIsLoading(false);
      return;
    }

    // Validate that at least one location field is filled
    if (
      !pageFrom &&
      !pageTo &&
      !paragraph &&
      !columnFrom &&
      !lineFrom &&
      !claimSection
    ) {
      setCitationLocationError(true);
      setLineColumnError(false);
      setCitationTextError(false);
      setIsLoading(false);
      return;
    }

    // Check if any column or line field is filled
    const hasColumnFields = columnFrom || columnTo;
    const hasLineFields = lineFrom || lineTo;

    // If any column/line field is provided, ensure all related fields are filled
    if (
      (hasColumnFields || hasLineFields) &&
      (!columnFrom || !columnTo || !lineFrom || !lineTo)
    ) {
      setLineColumnError(true);
      setCitationLocationError(false);
      setCitationTextError(false);
      setIsLoading(false);
      return;
    }

    // Validate ranges
    if (
      (columnFrom && columnTo && columnTo < columnFrom) ||
      (pageFrom && pageTo && pageTo < pageFrom)
    ) {
      setColumnRangeError(columnTo < columnFrom);
      setPageRangeError(pageTo < pageFrom);
      setIsLoading(false);
      return;
    }

    await addCitationFromPatentViewer(
      rowIndex,
      referenceId,
      -1,
      citationText,
      {
        pages: [pageFrom, pageTo],
        paragraph: paragraph,
        lines: [lineFrom, lineTo],
        columns: [columnFrom, columnTo],
        claimSection: claimSection,
      },
      true,
      referenceFiguresToAttach,
      invalidityId,
      [],
      () => {},
      () => {},
      claimNumber,
    );

    // Clear all errors before proceeding
    setCitationTextError(false);
    setCitationLocationError(false);
    setLineColumnError(false);
    setColumnRangeError(false);
    setLineRangeError(false);
    setPageRangeError(false);
    onClose();
  };

  const handleAttachImagesToCitation = async (files: File[]) => {
    for (const file of files) {
      if (file) {
        const response = await uploadImageToS3(file);
        const imageUrl = response.data.url;
        setReferenceFiguresToAttach([...referenceFiguresToAttach, imageUrl]);
      }
    }
  };

  const handleAttachFigures = (figureUrls: string[]) => {
    setReferenceFiguresToAttach([...referenceFiguresToAttach, ...figureUrls]);
  };

  const removeFromReferenceFiguresToAttach = (imageUrl: string) => {
    setReferenceFiguresToAttach(
      referenceFiguresToAttach.filter((url) => url !== imageUrl),
    );
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-4xl">
        <DialogHeader>
          <DialogTitle>
            Add citation to {elementType === ElementType.FEATURE ? "feature" : "claim"}{" "}
            {claimNumber} for {currentProject.documentsToNicknames[referenceId]}
          </DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <CitationForm
            citationText={citationText}
            setCitationText={setCitationText}
            pageFrom={pageFrom}
            setPageFrom={setPageFrom}
            pageTo={pageTo}
            setPageTo={setPageTo}
            paragraph={paragraph}
            setParagraph={setParagraph}
            lineFrom={lineFrom}
            setLineFrom={setLineFrom}
            lineTo={lineTo}
            setLineTo={setLineTo}
            claimSection={claimSection}
            setClaimSection={setClaimSection}
            columnFrom={columnFrom}
            setColumnFrom={setColumnFrom}
            columnTo={columnTo}
            setColumnTo={setColumnTo}
            citationTextError={citationTextError}
            citationLocationError={citationLocationError}
            lineColumnError={lineColumnError}
            columnRangeError={columnRangeError}
            lineRangeError={lineRangeError}
            pageRangeError={pageRangeError}
          />

          {/* Add image display section */}
          <div className="flex flex-wrap items-center gap-2">
            {referenceFiguresToAttach.map((imageUrl) => (
              <ImageContainer key={imageUrl}>
                <ReferenceImage
                  imageUrl={imageUrl}
                  imageName={imageUrl.split("/").pop() || ""}
                />
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => removeFromReferenceFiguresToAttach(imageUrl)}
                    >
                      <Cross2Icon className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Remove Image</TooltipContent>
                </Tooltip>
              </ImageContainer>
            ))}
          </div>

          {/* Add image attachment button */}
          <div className="flex items-center justify-start gap-2">
            <Button variant="outline" onClick={() => setFigureSelectModalOpen(true)}>
              Browse Figures
            </Button>
            <Button variant="outline" onClick={() => setImageUploadModalOpen(true)}>
              Upload Images
            </Button>
          </div>
        </div>

        <DialogFooter>
          <div className="flex items-center justify-end gap-2">
            <Button variant="outline" onClick={() => onClose()} disabled={isLoading}>
              Cancel
            </Button>
            <LoadingButton
              variant="default"
              onClick={handleSave}
              loading={isLoading}
              disabled={isLoading}
            >
              {isLoading ? "Adding..." : "Add"}
            </LoadingButton>
          </div>
        </DialogFooter>

        {/* Add modals */}
        <ImageUploadModal
          open={imageUploadModalOpen}
          handleClose={() => setImageUploadModalOpen(false)}
          handleSubmitFiles={handleAttachImagesToCitation}
        />

        <ReferenceFigureSelectModal
          open={figureSelectModalOpen}
          handleClose={() => setFigureSelectModalOpen(false)}
          handleAttachFigures={handleAttachFigures}
          refId={referenceId}
          existingFigureUrls={[]}
          isAddingCitation={true}
          setReferenceFiguresToAttach={setReferenceFiguresToAttach}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddCitationModal;
