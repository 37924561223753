/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ApiResponse, DocumentStatus, ParentType, ProjectStatus } from "@/types";
import { ClaimStatusReason } from "@/types/project";
import { toCamelCase, toSnakeCase } from "@/utils/dataUtils";
import { filterDocumentSections } from "@/utils/projectUtils";
import { useAppStateStore, useProjectStore } from "../store";
import { useApi } from "./";

/**
 * @description Hook for handling visualization operations relating to claim chart projects
 * @returns {object} - The visualization operations
 */
const useViz = () => {
  const { postRequest, getRequest, postRequestFile, handleError } = useApi();
  const {
    currentSubject,
    currentProject,
    currentPortfolioId,
    updateChartData,
    updateCurrentProject,
    updateCurrentSubject,
    updateSelectedReferences,
    updateProjectsList,
    updateArchivedProjectsList,
    updateCurrentParent,
    updateCurrentPortfolioId,
    updateCurrentPortfolio,
    clearCurrentProject,
    updateCurrentProjectId,
    currentPortfolio,
  } = useProjectStore();
  const { updateSearchChatProjectId, updateIsLoading, addErrorMessage } =
    useAppStateStore();

  /**
   * @description Fetches the reference metadata
   * @param {array} referenceIds - The ids of the references to fetch the metadata for
   */
  const getReferenceMetadata = async (referenceIds: string[]): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_reference_metadata", {
        reference_ids: referenceIds,
      });
      const referenceMetadata = response.data;

      updateSelectedReferences(toCamelCase(referenceMetadata, true));
      return {
        success: true,
        data: referenceMetadata,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching reference metadata");
    }
  };

  /**
   * @description Uploads an image to S3
   * @param {File} file - The file to upload to S3
   */
  const uploadImageToS3 = async (file: File): Promise<ApiResponse> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await postRequestFile("post_upload_image_to_s3", formData);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading image to S3");
    }
  };

  /**
   * @description Updates the note for a reference in the project
   * @param {string} projectId - The id of the project to update the note for
   * @param {string} referenceId - The id of the reference to update the note for
   * @param {string} note - The new note for the reference
   */
  const updateProjectReferenceNote = async (
    projectId: string,
    referenceId: string,
    note: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_project_reference_note", {
        project_id: projectId,
        reference_id: referenceId,
        note: note,
      });

      if (currentProject && currentProject.references) {
        const newReferences = [...currentProject.references];
        newReferences.map((reference) => {
          if (reference.id === referenceId) {
            reference.note = note;
          }
        });
        updateCurrentProject({
          id: currentProject.id,
          name: currentProject.name,
          references: newReferences,
        });
      }
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating project reference note");
    }
  };

  /**
   * @description Updates the note for a reference in the project
   * @param {string} projectId - The id of the project to update the note for
   * @param {string} referenceId - The id of the reference to update the note for
   * @param {string} newName - The new note for the reference
   */
  const updateProjectReferenceNickname = async (
    projectId: string,
    referenceId: string,
    newName: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_project_reference_nickname", {
        project_id: projectId,
        reference_id: referenceId,
        name: newName,
      });
      if (currentProject) {
        const nicknames = { ...currentProject.documentsToNicknames };
        nicknames[referenceId] = newName;
        updateCurrentProject({
          id: currentProject.id,
          name: currentProject.name,
          documentsToNicknames: nicknames,
        });
      }
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating project reference note");
    }
  };

  /**
   * @description Updates the note for a reference in the project
   * @param {string} projectId - The id of the project to update the note for
   * @param {string} referenceId - The id of the reference to update the note for
   * @param {string} newName - The new note for the reference
   */
  const updateReferenceTitle = async (
    referenceId: string,
    newName: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_document_name", {
        document_id: referenceId,
        name: newName,
      });

      if (currentProject.id) {
        updateCurrentProject({
          id: currentProject.id,
          name: currentProject.name,
          references: currentProject.references.map((reference) =>
            reference.id === referenceId ? { ...reference, title: newName } : reference,
          ),
        });
      }
      if (currentPortfolio.id) {
        updateCurrentPortfolio({
          ...currentPortfolio,
          references: currentPortfolio.references.map((reference) =>
            reference.id === referenceId ? { ...reference, title: newName } : reference,
          ),
        });
      }
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating project reference note");
    }
  };

  /**
   * @description Gets the search chat project id
   */
  const getSearchChatProjectId = async (): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_search_chat_project_id");
      updateSearchChatProjectId(response.data.id);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting search chat project id");
    }
  };

  /**
   * @description Gets the full document for a reference
   * @param {string} projectId - The id of the project to get the full document for
   * @param {string} referenceId - The id of the reference to get the full document for
   */
  const getFullDocument = async (referenceId: string): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        reference_id: referenceId,
      };

      const response = await getRequest("get_full_document", payload);
      const strippedBody = filterDocumentSections(response.data.body);
      const updatedResponse = {
        ...response.data,
        body: strippedBody,
      };
      return {
        success: true,
        data: toCamelCase(updatedResponse, false),
      };
    } catch (error) {
      return handleError(error, "Error getting full document");
    }
  };

  /**
   * @description Gets the user files for a user
   * @param {string} userEmail - The email of the user to get the files for
   */
  const getUserFiles = async (): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_user_files");
      return { success: true, data: toCamelCase(response.data, true) };
    } catch (error) {
      return handleError(error, "Error getting user files");
    }
  };

  /**
   * @description Updates the document details
   * @param {string} documentId - The id of the document to update the details for
   * @param {object} options - Additional options to pass to the request
   */
  const updateDocumentDetails = async (
    documentId: string,
    options: any,
  ): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        document_id: documentId,
      };
      Object.keys(options).forEach((key) => {
        if (options[key]) {
          payload[key] = options[key];
        }
      });
      const response = await postRequest("post_update_document_details", payload);
      updateCurrentSubject({ ...currentSubject, abstract: payload.abstract });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating document details");
    }
  };

  /**
   * @description Uploads a file to S3
   * @param {File} file - The file to upload to S3
   */
  const uploadFile = async (
    file: File,
    isStandard: boolean = false,
  ): Promise<ApiResponse> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("is_standard", String(isStandard));
      const response = await postRequest("post_upload_file", formData);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading file");
    }
  };

  /**
   * @description Fetches all projects for a user
   */
  const getUserProjects = async (): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_project_list");
      const archivedProjectsList = response.data.filter(
        (project: any) => project.status === ProjectStatus.ARCHIVED,
      );
      const activeProjectsList = response.data.filter(
        (project: any) => project.status !== ProjectStatus.ARCHIVED,
      );
      updateProjectsList(activeProjectsList);
      updateArchivedProjectsList(archivedProjectsList);
      return { success: true, status: response.status };
    } catch (error) {
      return handleError(error, "Error fetching user projects");
    }
  };

  /**
   * @description Fetches portfolio metadata and updates store
   * @param {string} portfolioId - The id of the portfolio to fetch metadata for
   */
  const getPortfolioMetadata = async (
    portfolioId: string,
    includeReferences: boolean = true,
    resetProject: boolean = false,
    portfolioName: string = "",
  ): Promise<ApiResponse> => {
    try {
      if (!portfolioId) {
        return { success: false, message: "Portfolio ID is required" };
      }
      if (resetProject) {
        updateIsLoading(portfolioId);
        updateCurrentParent(ParentType.PORTFOLIO);
        updateCurrentPortfolioId(portfolioId);
        updateCurrentProjectId("");
        updateCurrentProject({
          id: "",
          name: "",
        });
        updateCurrentPortfolio({
          ...currentPortfolio,
          id: portfolioId,
          name: portfolioName,
        });
      }
      const response = await getRequest("get_portfolio_metadata", {
        portfolio_id: portfolioId,
        include_references: includeReferences,
      });
      const portfolioMetadata = response.data;

      const documentsToNicknames = portfolioMetadata.projects.reduce(
        (acc: Record<string, string>, project: any) => {
          // Add project's documents_to_nicknames if it exists
          if (project.documents_to_nicknames) {
            acc = { ...acc, ...project.documents_to_nicknames };
          }

          return acc;
        },
        {},
      );

      updateCurrentPortfolio({
        id: portfolioMetadata.id,
        name: portfolioMetadata.name,
        summaries: portfolioMetadata.document_summaries,
        documentsToNicknames: documentsToNicknames,
        projects: portfolioMetadata.projects.map((project: any) => ({
          ...toCamelCase(project, false),
          subject: project.subject ? toCamelCase(project.subject, false) : null,
        })),
        type: portfolioMetadata.type,
        owner: portfolioMetadata.created_by,
        // settings: portfolioMetadata.settings,
        references:
          includeReferences && portfolioMetadata.references
            ? toCamelCase(portfolioMetadata.references, true)
            : null,

        settings: {
          defaultToAssertedClaims: portfolioMetadata.settings
            ? portfolioMetadata.settings.default_to_asserted_claims
            : false,
        },
      });

      if (portfolioMetadata.id !== currentPortfolioId) {
        clearCurrentProject();
        updateCurrentParent(ParentType.PORTFOLIO);
        updateCurrentPortfolioId(portfolioMetadata.id);
      }

      return { success: true, data: portfolioMetadata };
    } catch (error) {
      return handleError(error, "Error fetching project metadata");
    } finally {
      updateIsLoading(null);
    }
  };

  /**
   * @description Fetches portfolio metadata and updates store
   * @param {string} portfolioId - The id of the portfolio to fetch metadata for
   */
  const getPortfolioReferences = async (portfolioId: string): Promise<ApiResponse> => {
    try {
      if (!portfolioId) {
        return { success: false, message: "Portfolio ID is required" };
      }
      const response = await getRequest("get_portfolio_references", {
        portfolio_id: portfolioId,
      });
      const [portfolioReferences, portfolioProjects] = response.data;

      const documentsToNicknames = portfolioProjects.reduce(
        (acc: Record<string, string>, project: any) => {
          // Add project's documents_to_nicknames if it exists
          if (project.documents_to_nicknames) {
            acc = { ...acc, ...project.documents_to_nicknames };
          }

          return acc;
        },
        {},
      );

      if (currentPortfolioId === portfolioId) {
        updateCurrentPortfolio({
          ...currentPortfolio,
          references: toCamelCase(portfolioReferences, true),
          documentsToNicknames: documentsToNicknames,
        });
      }
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching project metadata");
    }
  };

  /**
   * @description Checks if there exists a final written decision for the given subject patent
   * @param {string} patentNumber - The patent number to check for
   */
  const getDoesIprExist = async (patentNumber: string): Promise<ApiResponse> => {
    try {
      if (!patentNumber) {
        return { success: false, message: "Patent number is required" };
      }
      const response = await getRequest("get_does_ipr_exist", {
        patent_number: patentNumber,
      });
      const exists = response.data;

      return { success: true, data: exists };
    } catch (error) {
      return handleError(error, "Error checking if IPR exists");
    }
  };

  /**
   * @description Returns model evaluation for a given project and references
   * @param {string} projectId - The id of the project to evaluate
   * @param {array} referenceIds - The ids of the references associated with the project
   */
  const getEvaluation = async (
    projectId: string,
    referenceIds: string[],
    patentNumber: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_evaluation", {
        project_id: projectId,
        reference_ids: referenceIds,
        patent_number: patentNumber,
      });

      const rawEvaluation = response.data.evaluation;
      const transformedEvaluation = rawEvaluation.map((item: any) => ({
        claim: item.Claim,
        claimLanguage: item["Claim Language"],
        modelReferences: item["Model References"],
        pdfReferences: item["PDF References"],
        missingReferences: item["Missing References"],
        incorrectReferences: item["Incorrect References"],
        accuracy: item.Accuracy,
        precision: item.Precision,
        recall: item.Recall,
      }));

      updateChartData(transformedEvaluation);
      return {
        success: true,
        data: transformedEvaluation,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching evaluation data");
    }
  };

  const getRemainingParagraphs = async (
    projectId: string,
    referenceId: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_remaining_paragraphs", {
        project_id: projectId,
        reference_id: referenceId,
      });
      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching remaining paragraphs");
    }
  };

  const removeUserFiles = async (referenceIds: string[]): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_remove_user_files", {
        reference_ids: referenceIds,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error removing user files");
    }
  };

  const getPatentApplicationDetails = async (number: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest(
        `post_patent_application_details?number=${encodeURIComponent(number)}`,
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Could not find a valid patent");
    }
  };

  const updateDocumentStatuses = async (
    document_ids: string[],
    status: DocumentStatus,

    isPortfolio: boolean = false,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_document_statuses", {
        document_ids: document_ids,
        status: status,
      });

      if (currentProject && currentProject.references) {
        updateCurrentProject({
          id: currentProject.id,
          name: currentProject.name,
          references: currentProject.references.map((ref) => ({
            ...ref,
            status: document_ids.includes(ref.id) ? status : ref.status,
          })),
        });
      }
      if (currentPortfolio && currentPortfolio.references) {
        updateCurrentPortfolio({
          ...currentPortfolio,
          references: currentPortfolio.references.map((ref) => ({
            ...ref,
            status: document_ids.includes(ref.id) ? status : ref.status,
          })),
        });
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Failed to dismiss statuses");
    }
  };

  const getProjectSummaryExport = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_invalidity_summary_export", {
        project_id: projectId,
      });

      const downloadFile = async (url: string) => {
        const fileResponse = await fetch(url);
        if (!fileResponse.ok) throw new Error("Failed to download the file");
        const fileBlob = await fileResponse.blob();
        const localUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = localUrl;
        const fileName = currentProject.name.replace(/[\s']/g, "");
        link.setAttribute("download", `${fileName}_Summary.docx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(localUrl);
      };

      if (response.data.url) {
        await downloadFile(response.data.url);
      } else {
        if (process.env.NODE_ENV !== "production") {
          console.error("No download URL found");
        }
        addErrorMessage("Error downloading file. Try again later.");
        return;
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      addErrorMessage("Error downloading file. Try again later.");
      return handleError(error, "Error fetching project summary export");
    }
  };

  const getPortfolioSummaryExport = async (
    portfolioId: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_portfolio_summary_export", {
        portfolio_id: portfolioId,
      });

      const downloadFile = async (url: string) => {
        const fileResponse = await fetch(url);
        if (!fileResponse.ok) throw new Error("Failed to download the file");
        const fileBlob = await fileResponse.blob();
        const localUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = localUrl;
        const fileName = currentProject.name.replace(/[\s']/g, "");
        link.setAttribute("download", `${fileName}_Summary.docx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(localUrl);
      };

      if (response.data.url) {
        await downloadFile(response.data.url);
      } else {
        if (process.env.NODE_ENV !== "production") {
          console.error("No download URL found");
        }
        addErrorMessage("Error downloading file. Try again later.");
        return;
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      addErrorMessage("Error downloading file. Try again later.");
      return handleError(error, "Error fetching project summary export");
    }
  };

  const updateSection112 = async (
    projectId: string,
    new_section: Array<Record<string, string>>,
    section_type: "enablement" | "indefiniteness" | "written_description",
  ): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        project_id: projectId,
      };
      payload[section_type] = new_section;
      const response = await postRequest("post_update_section_112", payload);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating section 112");
    }
  };

  const updateDocumentSummary = async (
    projectId: string,
    documentId: string,
    summary: string,
    isPortfolio: boolean,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_document_summary", {
        project_id: projectId,
        document_id: documentId,
        summary: summary,
        is_portfolio: isPortfolio,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating document summary");
    }
  };

  const generateClaimAmendments = async (
    claimText: string,
    reason: ClaimStatusReason,
  ): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        claim_text: claimText,
        reason: {
          type: reason.type,
          examiner_commentary: reason.examinerCommentary,
          reference_citations: toSnakeCase(reason.referenceCitations),
        },
        application_id: currentSubject.id,
      };

      const response = await postRequest("post_generate_claim_amendment", payload);

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      console.error("Error in generateClaimAmendments:", error);
      return handleError(error, "Failed to generate claim amendments");
    }
  };

  const generateClaimArgument = async (
    claimText: string,
    reason: ClaimStatusReason,
  ): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        claim_text: claimText,
        reason: {
          type: reason.type,
          examiner_commentary: reason.examinerCommentary,
          reference_citations: toSnakeCase(reason.referenceCitations), // Include citations for argument generation
        },
        application_id: currentSubject.id,
      };

      const response = await postRequest("post_generate_claim_argument", payload);

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      console.error("Error in generateClaimArguments:", error);
      return handleError(error, "Failed to generate claim arguments");
    }
  };

  return {
    uploadImageToS3,
    updateProjectReferenceNote,
    getSearchChatProjectId,
    getFullDocument,
    getUserFiles,
    updateDocumentDetails,
    updateProjectReferenceNickname,
    uploadFile,
    getUserProjects,
    getPortfolioMetadata,
    getDoesIprExist,
    getEvaluation,
    getRemainingParagraphs,
    removeUserFiles,
    getReferenceMetadata,
    getPatentApplicationDetails,
    getPortfolioReferences,
    updateDocumentStatuses,
    getProjectSummaryExport,
    getPortfolioSummaryExport,
    updateSection112,
    updateDocumentSummary,
    generateClaimAmendments,
    generateClaimArgument,
    updateReferenceTitle,
  };
};

export default useViz;
