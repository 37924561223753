/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

/*
 * Shared form component for citation text and location fields.
 */
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { FloatingLabelInput } from "@/components/ui/floating-input";
import { cn } from "@/lib/utils";
import React from "react";

interface CitationFormProps {
  citationText: string;
  setCitationText: (text: string) => void;

  pageFrom: number | null;
  setPageFrom: (value: number | null) => void;
  pageTo: number | null;
  setPageTo: (value: number | null) => void;

  paragraph: number | null;
  setParagraph: (value: number | null) => void;

  lineFrom: number | null;
  setLineFrom: (value: number | null) => void;
  lineTo: number | null;
  setLineTo: (value: number | null) => void;

  claimSection: string | null;
  setClaimSection: (value: string | null) => void;

  columnFrom: number | null;
  setColumnFrom: (value: number | null) => void;
  columnTo: number | null;
  setColumnTo: (value: number | null) => void;

  // Validation errors
  citationTextError: boolean;
  citationLocationError: boolean;
  lineColumnError: boolean;
  columnRangeError: boolean;
  lineRangeError: boolean;
  pageRangeError: boolean;
}

export const CitationForm: React.FC<CitationFormProps> = ({
  citationText,
  setCitationText,

  pageFrom,
  setPageFrom,
  pageTo,
  setPageTo,

  paragraph,
  setParagraph,

  lineFrom,
  setLineFrom,
  lineTo,
  setLineTo,

  claimSection,
  setClaimSection,

  columnFrom,
  setColumnFrom,
  columnTo,
  setColumnTo,

  citationTextError,
  citationLocationError,
  lineColumnError,
  columnRangeError,
  lineRangeError,
  pageRangeError,
}) => {
  // Remove validation from the change handlers
  const handleColumnToChange = (value: string) => {
    const newValue = parseInt(value);
    if (!value) {
      setColumnTo(null);
      return;
    }
    setColumnTo(newValue);
  };

  const handleLineToChange = (value: string) => {
    const newValue = parseInt(value);
    if (!value) {
      setLineTo(null);
      return;
    }
    setLineTo(newValue);
  };

  return (
    <div className="space-y-2">
      <div className="flex flex-row gap-4">
        {/* Pages Group */}
        <div className="flex flex-col">
          <span className="text-xs text-muted-foreground mb-1">Pages</span>
          <div className="flex gap-2">
            <FloatingLabelInput
              value={pageFrom || ""}
              placeholder="From"
              onChange={(e) => setPageFrom(parseInt(e.target.value) || null)}
              className={cn("h-9 min-w-[80px]", pageRangeError && "border-red-500")}
              label="From"
              error={pageRangeError ? "Invalid range" : ""}
            />
            <FloatingLabelInput
              value={pageTo || ""}
              placeholder="To"
              onChange={(e) => setPageTo(parseInt(e.target.value) || null)}
              className={cn("h-9 min-w-[80px]", pageRangeError && "border-red-500")}
              label="To"
              error={pageRangeError ? "Invalid range" : ""}
            />
          </div>
        </div>

        {/* Columns Group */}
        <div className="flex flex-col">
          <span className="text-xs text-muted-foreground mb-1">Columns</span>
          <div className="flex gap-2">
            <FloatingLabelInput
              value={columnFrom || ""}
              placeholder="From"
              onChange={(e) => setColumnFrom(parseInt(e.target.value) || null)}
              className={cn(
                "h-9 min-w-[80px]",
                (lineColumnError || columnRangeError) && "border-red-500",
              )}
              label="From"
              error={
                lineColumnError ? "Required" : columnRangeError ? "Invalid range" : ""
              }
            />
            <FloatingLabelInput
              value={columnTo || ""}
              placeholder="To"
              onChange={(e) => handleColumnToChange(e.target.value)}
              className={cn(
                "h-9 min-w-[80px]",
                (lineColumnError || columnRangeError) && "border-red-500",
              )}
              label="To"
              error={
                lineColumnError ? "Required" : columnRangeError ? "Invalid range" : ""
              }
            />
          </div>
        </div>

        {/* Lines Group */}
        <div className="flex flex-col">
          <span className="text-xs text-muted-foreground mb-1">Lines</span>
          <div className="flex gap-2">
            <FloatingLabelInput
              value={lineFrom || ""}
              placeholder="From"
              onChange={(e) => setLineFrom(parseInt(e.target.value) || null)}
              className={cn(
                "h-9 min-w-[80px]",
                (lineColumnError || lineRangeError) && "border-red-500",
              )}
              label="From"
              error={
                lineColumnError ? "Required" : lineRangeError ? "Invalid range" : ""
              }
            />
            <FloatingLabelInput
              value={lineTo || ""}
              placeholder="To"
              onChange={(e) => handleLineToChange(e.target.value)}
              className={cn(
                "h-9 min-w-[80px]",
                (lineColumnError || lineRangeError) && "border-red-500",
              )}
              label="To"
              error={
                lineColumnError ? "Required" : lineRangeError ? "Invalid range" : ""
              }
            />
          </div>
        </div>

        {/* Paragraph */}
        <div className="flex flex-col">
          <span className="text-xs text-muted-foreground mb-1">Paragraph</span>
          <FloatingLabelInput
            value={paragraph || ""}
            onChange={(e) => setParagraph(parseInt(e.target.value) || null)}
            className="h-9 min-w-[80px]"
          />
        </div>

        {/* Claim Section */}
        <div className="flex flex-col">
          <span className="text-xs text-muted-foreground mb-1">Claim</span>
          <FloatingLabelInput
            value={claimSection || ""}
            onChange={(e) => setClaimSection(e.target.value || null)}
            className="h-9 min-w-[80px]"
          />
        </div>
      </div>

      <AutosizeTextarea
        value={citationText}
        placeholder="Citation text"
        onChange={(e) => setCitationText(e.target.value)}
        className={cn(citationTextError && "border-red-500")}
      />

      {citationTextError && (
        <p className="text-red-500 text-sm">Citation text is required</p>
      )}

      {citationLocationError && (
        <p className="text-red-500 text-sm">
          At least one location field (page, paragraph, or columns/lines) is required
        </p>
      )}

      {lineColumnError && (
        <p className="text-red-500 text-sm">
          Both 'from' and 'to' values are required for columns/lines
        </p>
      )}

      {columnRangeError && (
        <p className="text-red-500 text-sm">
          'To' value cannot be less than 'From' value in columns
        </p>
      )}

      {lineRangeError && (
        <p className="text-red-500 text-sm">
          'To' value cannot be less than 'From' value in lines
        </p>
      )}

      {pageRangeError && (
        <p className="text-red-500 text-sm">
          'To' value cannot be less than 'From' value in pages
        </p>
      )}
    </div>
  );
};

export default CitationForm;
