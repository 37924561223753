import { cn } from "@/lib/utils";
import { ArrowUpIcon } from "lucide-react";
import { Button } from "./button";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

interface ScrollTopButtonProps {
  className?: string;
  show: boolean;
}

export const ScrollTopButton = ({ className, show }: ScrollTopButtonProps) => {
  const scrollToTop = () => {
    const scrollArea = document.querySelector("[data-radix-scroll-area-viewport]");
    if (scrollArea) {
      scrollArea.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          variant="outline"
          className={cn(
            "transition-all bg-accent duration-300 opacity-0 translate-y-full",
            show && "opacity-100 translate-y-0",
            className,
          )}
          onClick={scrollToTop}
        >
          <ArrowUpIcon className="h-4 w-4 mr-2" />
          Top
        </Button>
      </TooltipTrigger>
      <TooltipContent side="top">Scroll to top</TooltipContent>
    </Tooltip>
  );
};
