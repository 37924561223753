/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DocumentViewer } from "@/components";
import { Button } from "@/components/ui/button";
import {
  DataTableHead,
  DataTableRowOutline,
  Table,
  TableBody,
  TableHeader,
} from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useVector, useViz } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import { splitId } from "@/utils/projectUtils";
import { ExternalLinkIcon, EyeOpenIcon, HandIcon } from "@radix-ui/react-icons";
import { AlignVerticalSpaceAround } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import HeaderFooterEditor from "./HeaderFooterEditor";
import InvalidityTableRow from "./InvalidityTableRow";
interface InvalidityTableProps {
  fetchReferenceChartData: () => void;
  onFillStart: () => void;
  onFillEnd: () => void;
  rowVisibility: string;
}

/**
 * @description Data table
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 */
const InvalidityTable: React.FC<InvalidityTableProps> = ({
  fetchReferenceChartData,
  onFillStart,
  onFillEnd,
  rowVisibility,
}) => {
  const { processParagraphsAsInvalidities } = useVector();
  const { getFullDocument, getRemainingParagraphs } = useViz();

  const { currentProjectId, currentProject, chartData, selectedElementType } =
    useProjectStore();
  const addErrorMessage = useAppStateStore((state) => state.addErrorMessage);
  const documentsToNumbers = useProjectStore(
    (state) => state.currentProject.documentsToNumbers,
  );
  const documentsToNicknames = useProjectStore(
    (state) => state.currentProject.documentsToNicknames,
  );
  const currentSubjectId = useProjectStore((state) => state.currentSubject.id);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [addingCitationKey, setAddingCitationKey] = useState<string>("");
  const [showDocumentViewer, setShowDocumentViewer] = useState<boolean>(false);
  const [documentViewerDocument, setDocumentViewerDocument] = useState<any>(null);
  const [isBoilerplateOpen, setIsBoilerplateOpen] = useState<boolean>(false);

  const referenceIdList =
    chartData.length > 0
      ? Object.keys(chartData[0]).filter(
          (key) =>
            key !== "claim_number" && key !== "claim_text" && key !== "invalidity_id",
        )
      : [];

  const handleShowPatentViewer = async (refId: string) => {
    const response = await getFullDocument(refId);
    if (response.success) {
      setDocumentViewerDocument(response.data);
      setShowDocumentViewer(true);
    } else {
      addErrorMessage(
        `Error getting patent ${refId} from project ${currentProject.name} `,
      );
      handleClosePreview();
    }
  };

  function levensteinDistance(a: string, b: string): number {
    const matrix = [];

    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1,
            matrix[i][j - 1] + 1,
            matrix[i - 1][j] + 1,
          );
        }
      }
    }
    const similarity = 1 - matrix[b.length][a.length] / Math.max(b.length, a.length);

    return similarity;
  }
  const handleFill = useCallback(
    async (refId: string) => {
      try {
        onFillStart();
        const paragraphsResponse = await getRemainingParagraphs(
          currentProjectId,
          refId,
        );
        if (paragraphsResponse.success) {
          const { ipr_number, ...paragraphData } = paragraphsResponse.data;
          const remainingParagraphs: Map<string, string[]> = new Map(
            Object.entries(paragraphData),
          );
          remainingParagraphs.forEach(async (paragraphNumbers, claimLanguage) => {
            // find the corresponding invalidity
            let bestInvalidityMatch = null;
            let bestScore = 0;

            chartData.forEach((invalidity) => {
              if (
                invalidity.claim_text
                  .toLowerCase()
                  .includes(claimLanguage.toLowerCase()) ||
                claimLanguage
                  .toLowerCase()
                  .includes(invalidity.claim_text.toLowerCase())
              ) {
                bestInvalidityMatch = invalidity;
                return; // This effectively breaks out of the forEach loop
              }
              const score = levensteinDistance(
                invalidity.claim_text.toLowerCase(),
                claimLanguage.toLowerCase(),
              );
              if (score > bestScore && score > 0.95) {
                bestScore = score;
                bestInvalidityMatch = invalidity;
              }
            });

            if (bestInvalidityMatch) {
              const invalidityId = bestInvalidityMatch[refId].invalidityId;
              const invaliditiesResponse = await processParagraphsAsInvalidities(
                currentProjectId,
                invalidityId,
                refId,
                ipr_number[0],
                paragraphNumbers,
              );
            }
          });
        } else {
          console.error("Failed to fetch remaining paragraphs");
        }
      } catch (error) {
        console.error("Error fetching remaining paragraphs:", error);
        addErrorMessage("Failed to fetch remaining paragraphs");
      } finally {
        onFillEnd();
      }
    },
    [currentProjectId, getRemainingParagraphs, addErrorMessage, onFillStart, onFillEnd],
  );

  const handleClosePreview = () => {
    setDocumentViewerDocument(null);
    setShowDocumentViewer(false);
  };

  useEffect(() => {
    setIsEditing(false);
  }, []);

  useEffect(() => {
    setIsEditing(false);
  }, [selectedElementType]);

  return (
    <div>
      {showDocumentViewer && (
        <DocumentViewer
          open={showDocumentViewer}
          handleClose={handleClosePreview}
          referenceId={documentViewerDocument?.id}
          citationText={""}
          startInChartMode={true}
        />
      )}
      <Table>
        <TableHeader>
          <DataTableRowOutline>
            <DataTableHead className="font-bold whitespace-nowrap">No.</DataTableHead>
            <DataTableHead className="font-bold whitespace-nowrap min-w-[250px]">
              {documentsToNicknames?.[currentSubjectId]
                ? `${documentsToNicknames?.[currentSubjectId]}`
                : splitId(documentsToNumbers?.[currentSubjectId] ?? "")}{" "}
              Language
            </DataTableHead>
            {referenceIdList.map((refId) => (
              <DataTableHead key={`${refId}-header`} className="whitespace-nowrap">
                <div className="flex justify-between items-center">
                  <span className="font-bold">
                    {documentsToNicknames?.[refId]
                      ? `${documentsToNicknames?.[refId]}`
                      : splitId(documentsToNumbers?.[refId] ?? "")}
                  </span>
                  <div className="flex">
                    {isLikelyPatentNumber(
                      currentProject.references.find((r) => r.id === refId)?.number,
                    ) && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            asChild
                            className="text-black hover:bg-primary-foreground/10 flex-shrink-0"
                          >
                            <a
                              href={`https://patents.google.com/patent/${currentProject.references.find(
                                (r) => r.id === refId,
                              )?.number}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <ExternalLinkIcon className="h-4 w-4" />
                            </a>
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Go to Google Patents</p>
                        </TooltipContent>
                      </Tooltip>
                    )}
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => setIsBoilerplateOpen(true)}
                        >
                          <AlignVerticalSpaceAround className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Edit header/footer</p>
                      </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleShowPatentViewer(refId)}
                        >
                          <EyeOpenIcon className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>View full document</p>
                      </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleFill(refId)}
                        >
                          <HandIcon className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Pull IPR Proceedings</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </div>
              </DataTableHead>
            ))}
          </DataTableRowOutline>
        </TableHeader>
        <TableBody>
          {chartData.map((row, index) => {
            // Check if row has any citations when visibility is "hide"
            const hasCitations = referenceIdList.some(
              (refId) => row[refId]?.citations?.length > 0,
            );
            if (rowVisibility === "hide" && !hasCitations) {
              return null;
            }

            return (
              <InvalidityTableRow
                key={index}
                row={row}
                rowIndex={index}
                addingCitationKey={addingCitationKey}
                setAddingCitationKey={setAddingCitationKey}
                setIsEditing={setIsEditing}
                fetchReferenceChartData={fetchReferenceChartData}
                isEditing={isEditing}
                rowVisibility={rowVisibility}
              />
            );
          })}
        </TableBody>
      </Table>
      <HeaderFooterEditor
        initialHeader={""}
        initialFooter={""}
        isOpen={isBoilerplateOpen}
        onClose={() => setIsBoilerplateOpen(false)}
        index={-1}
        defaultUseFormatForAllElements={true}
      />
    </div>
  );
};

export default InvalidityTable;
