import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { useProjectStore } from "@/store";
import { ClaimDetails, ClaimStatus, ClaimStatusReason } from "@/types/project";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ClaimDetailsCollapsible } from "../ClaimDetailsCollapsible";
import { CitedArtContent } from "./ClaimCitedArtContent";
import { ClaimReasonTypesSelector } from "./ClaimReasonTypesSelector";
import { OfficeActionInfoRow } from "./OfficeActionInfoRow";

interface ClaimRowProps {
  claim: ClaimDetails;
  isGlobalExpanded: boolean;
  onEdit?: () => void;
}

const getClaimText = (
  claims: Record<string, string>[],
  claimNumber: string,
): string => {
  let claimLanguage = "";
  claims.forEach((claimObj) => {
    Object.entries(claimObj).forEach(([key, value]) => {
      const mainClaimNumber = key.split(".")[0];
      if (mainClaimNumber === claimNumber) {
        claimLanguage += value + " ";
      }
    });
  });
  return claimLanguage.trim();
};

const formatStatus = (status: ClaimStatus): string =>
  status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

export const ClaimRow = memo(({ claim, isGlobalExpanded }: ClaimRowProps) => {
  const { currentProject, draftingClaimID, updateDraftingClaimID } = useProjectStore();
  const reasons = claim.reasons;
  const [isLocalExpanded, setIsLocalExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const isObjected = () => claim.status.toLowerCase() === ClaimStatus.OBJECTED;

  // Use global expanded state as a side effect
  useEffect(() => {
    setIsLocalExpanded(isGlobalExpanded);
  }, [isGlobalExpanded]);

  if (!reasons) return null;

  // State management
  const [isCitationsSectionVisible, setIsCitationsSectionVisible] = useState(false);
  const [selectedReason, setSelectedReason] = useState<
    ClaimStatusReason & { index: number; claimIndex: number }
  >(
    reasons.length > 0
      ? { ...reasons[0], index: 0, claimIndex: parseInt(claim.claimNumber) - 1 }
      : null,
  );

  // Event handlers
  const handleReasonSelect = useCallback(
    (index: number) => {
      if (selectedReason?.index !== index) {
        setSelectedReason({
          ...reasons[index],
          index,
          claimIndex: parseInt(claim.claimNumber) - 1,
        });
      }
    },
    [selectedReason?.index, reasons, claim.claimNumber],
  );

  const handleDropdownClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const toggleSectionVisibility = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCitationsSectionVisible((prev) => !prev);
  }, []);

  const handleEditClick = useCallback(() => {
    if (draftingClaimID === claim.claimNumber) {
      updateDraftingClaimID(null);
      setIsEditing(false);
    } else {
      updateDraftingClaimID(claim.claimNumber);
      setIsEditing(true);
    }
  }, [claim.claimNumber, draftingClaimID, updateDraftingClaimID]);

  useEffect(() => {
    setIsEditing(draftingClaimID === claim.claimNumber);
  }, [draftingClaimID, claim.claimNumber]);

  const handleCollapsibleChange = useCallback(
    (isExpanded: boolean) => {
      setIsLocalExpanded(isExpanded);
      if (isEditing) {
        setIsEditing(false);
        updateDraftingClaimID(null);
      }
    },
    [isEditing, updateDraftingClaimID],
  );

  // Claim row text and configuration
  const claimText = useMemo(
    () => getClaimText(currentProject.claims, claim.claimNumber),
    [currentProject.claims, claim.claimNumber],
  );

  const rowItems = [
    { label: "Claim", content: claim.claimNumber },
    {
      label: "Status",
      content: formatStatus(claim.status),
    },
    // Only include Types if there are reasons with types
    ...(reasons.some((reason) => reason.type !== null)
      ? [
          {
            label: reasons.length <= 1 || isObjected() ? "Type" : "Types",
            content: isObjected() ? (
              "-"
            ) : reasons.length <= 1 ? (
              reasons[0].type && reasons[0].type.trim() !== "" ? (
                reasons[0].type
              ) : (
                "-"
              )
            ) : (
              <ClaimReasonTypesSelector
                status={claim.status}
                reasons={reasons}
                selectedReason={selectedReason}
                onReasonSelect={handleReasonSelect}
                onDropdownClick={handleDropdownClick}
              />
            ),
            isDropdown:
              reasons.length > 1 && claim.status.toLowerCase() !== ClaimStatus.OBJECTED,
          },
        ]
      : []),
    // Only include Cited Art if there are reasons with reference citations
    ...(reasons.some((reason) => reason.referenceCitations?.length > 0)
      ? [
          {
            label: "Cited Art",
            content: (
              <CitedArtContent
                status={claim.status}
                reasons={reasons}
                selectedReason={selectedReason}
                onToggleVisibility={toggleSectionVisibility}
                isSectionVisible={isCitationsSectionVisible}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <Collapsible open={isLocalExpanded} onOpenChange={handleCollapsibleChange}>
      <CollapsibleTrigger asChild>
        <div>
          <OfficeActionInfoRow
            items={rowItems}
            gridCols="grid-cols-[10%_15%_25%_50%]"
            status={claim.status}
            onEdit={handleEditClick}
            showCarat={true}
            isCaratOpen={isLocalExpanded}
          />
        </div>
      </CollapsibleTrigger>

      <CollapsibleContent className="mt-2 rounded-lg">
        <div className="space-y-0.5 border border-gray-200 rounded-md px-4 py-3">
          {reasons.length > 0 ? (
            <ClaimDetailsCollapsible
              selectedReason={selectedReason}
              reasons={reasons}
              claimText={claimText}
              status={claim.status}
              isCitationsSectionVisible={isCitationsSectionVisible}
              isEditing={isEditing}
            />
          ) : (
            <div className="text-sm">
              <p>No reasons were found, please try re-processing the office action.</p>
            </div>
          )}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
});

ClaimRow.displayName = "ClaimRow";
