/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DocumentViewer, InfoPopover, PatentSourceNumberButton } from "@/components";
import { DataTable } from "@/components/table/DataTable";
import { TableTags } from "@/components/table/TableTags";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Spinner } from "@/components/ui/spinner";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useViz } from "@/hooks";
import { useAppStateStore, useProcessStore, useProjectStore } from "@/store";
import {
  conflictingReferenceProcesses,
  DocumentStatus,
  DocumentType,
  Patent,
} from "@/types";
import { ColumnDef, Row } from "@tanstack/react-table";
import he from "he";
import { MoreVertical } from "lucide-react";
import { DateTime } from "luxon";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";

type CustomColumnDef<TData, TValue = unknown> = ColumnDef<TData, TValue> & {
  hidden?: boolean;
};

type FilterOption = { label: string; value: string };
type Filters = {
  type?: { label: string; values: FilterOption[] };
  prefix: { label: string; values: FilterOption[] };
  tags?: { label: string; values: FilterOption[] };
};

interface DocumentsTableProps {
  references: Patent[];
  onDeleteRow?: (reference: Patent) => void;
  onRowSelection?: (references: Patent[]) => void;

  height?: string;
  children?: React.ReactNode;
  enableRowDelete?: boolean;
  isDeleteSelectedLoading?: boolean;
  onReprocessSelected?: (references: Patent[], isReprocess: boolean) => void;
  onDismissSelected?: (references: Patent[]) => void;
  onDeleteSelected?: (selectedReferences: Patent[]) => void;
  onSaveName?: (reference: Patent, newName: string, isNickname: boolean) => void;
  onSaveNote?: (reference: Patent, newNote: string) => void;
  onTagUpdate?: (selectedRows: Patent[], selectedTags: string[]) => void;
  onSelectProject?: (reference: Patent) => void;
  onRowClick?: (reference: Patent) => void;
  isReference?: boolean;
  isPortfolio?: boolean;
  isSearch?: boolean;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  references,
  onDeleteRow,
  onRowSelection,
  height,
  children,
  enableRowDelete,
  onDeleteSelected,
  isSearch,
  isDeleteSelectedLoading = false,
  onReprocessSelected,
  onDismissSelected,
  onTagUpdate,
  onSelectProject,
  onSaveName,
  onSaveNote,
  onRowClick,
  isReference = false,
  isPortfolio = false,
}) => {
  const { getFullDocument } = useViz();
  const { areProcessesPending } = useProcessStore();

  const checkboxStart = !isSearch;
  const enableRowActions = !isSearch;
  const showTableActions = true;
  const enableRename = !isSearch;
  const showSubjects = isPortfolio && isReference;
  const showReferenceEditCols = isReference && !isSearch;
  const showName = !isSearch;

  const {
    currentProjectId,
    currentPortfolioId,
    currentPortfolio,
    documentsToNicknamesPortfolio,
    documentsToNicknamesProject,
  } = useProjectStore((state) => ({
    currentProjectId: state.currentProjectId,
    currentPortfolioId: state.currentPortfolioId,
    currentParent: state.currentParent,
    currentProject: state.currentProject,
    updateCurrentProject: state.updateCurrentProject,
    currentPortfolio: state.currentPortfolio,
    documentsToNicknamesPortfolio: state.currentPortfolio?.documentsToNicknames || {},
    documentsToNicknamesProject: state.currentProject?.documentsToNicknames || {},
  }));

  let documentsToNicknames: {
    [key: string]: string;
  } = {};
  if (isPortfolio) {
    documentsToNicknames = documentsToNicknamesPortfolio || {};
  } else {
    documentsToNicknames = documentsToNicknamesProject || {};
  }
  const { addErrorMessage } = useAppStateStore();

  const handleRowSelection = (selectedRows: Patent[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRows);
    }
  };

  const isReferenceDisabled =
    areProcessesPending({
      types: conflictingReferenceProcesses,
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: conflictingReferenceProcesses,
      portfolioId: currentPortfolioId,
    });

  const [editingNoteRefId, setEditingNoteRefId] = useState<string>("");
  const [editingNameRefId, setEditingNameRefId] = useState<string>("");
  const [noteContent, setNoteContent] = useState<string>("");
  const [newName, setNewName] = useState<string>("");
  const [patentDetails, setPatentDetails] = useState<any>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const handleRemoveRow = (reference: Patent) => {
    if (reference && onDeleteRow) {
      onDeleteRow(reference);
    }
  };

  const handleEditNotes = (reference: Patent) => {
    if (reference) {
      setEditingNoteRefId(reference.id);
      setNoteContent(reference.note || "");
    }
  };

  const handleSaveNote = async (reference: Patent, newNote: string) => {
    await onSaveNote?.(reference, newNote);
    setEditingNoteRefId("");
    setNoteContent("");
  };

  const handleSaveName = async (
    reference: Patent,
    newName: string,
    isNickname: boolean,
  ) => {
    await onSaveName?.(reference, newName, isNickname);
    setEditingNameRefId("");
    setNewName("");
  };

  const handleViewDetails = async (reference: Patent) => {
    const response = await getFullDocument(reference.id);
    if (!response.success) {
      addErrorMessage(
        response.message || "An error occurred while fetching the document details.",
      );
    } else {
      setPatentDetails(response.data);
    }
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setPatentDetails(null);
    setShowPreview(false);
  };

  const [allTags, setAllTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    const tags = new Set<string>();
    references.forEach((ref) => {
      if (Array.isArray(ref.tags)) {
        ref.tags.forEach((tag) => tags.add(tag));
      }
    });
    setAllTags(Array.from(tags));
  }, [references]);

  const handleEditName = (reference: Patent) => {
    setEditingNameRefId(reference.id);
    setNewName(
      documentsToNicknames && documentsToNicknames[reference.id]
        ? documentsToNicknames[reference.id]
        : reference.name || "",
    );
  };

  // Sort functions for title and name
  const multiLingualSort = (rowA: Row<Patent>, rowB: Row<Patent>, columnId: string) => {
    const collator = new Intl.Collator(["ko", "ja", "zh", "en"], {
      sensitivity: "base",
      caseFirst: "false",
    });

    if (columnId === "title") {
      const valueA = (rowA.original.title || rowA.original.name || "") as string;
      const valueB = (rowB.original.title || rowB.original.name || "") as string;
      return collator.compare(valueA, valueB);
    }

    const valueA = rowA.getValue(columnId) as string;
    const valueB = rowB.getValue(columnId) as string;
    return collator.compare(valueA, valueB);
  };

  const nicknameSort = (rowA: Row<Patent>, rowB: Row<Patent>) => {
    const collator = new Intl.Collator(["ko", "ja", "zh", "en"], {
      sensitivity: "base",
      caseFirst: "false",
    });
    const valueA = documentsToNicknames?.[rowA.original.id] || rowA.original.name || "";
    const valueB = documentsToNicknames?.[rowB.original.id] || rowB.original.name || "";
    return collator.compare(valueA, valueB);
  };

  const checkboxColumn = {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  };

  const statusTooltipContent = (
    <>
      <p className="text-sm text-muted-foreground">
        <b>Processed</b>: No action needed.
      </p>
      <p className="text-sm text-muted-foreground">
        <b>Reprocess</b>: Click "Resolve Statuses" to reprocess with updated algorithms.
      </p>
      <p className="text-sm text-muted-foreground">
        <b>Rechart</b>: Click "Resolve Statuses" to rechart with updated algorithms or
        context.
      </p>
      <p className="text-sm text-muted-foreground">
        <b>Reupload</b>: Delete and reupload the document.
      </p>
    </>
  );

  const [editingTitleRefId, setEditingTitleRefId] = useState<string>("");
  const [titleContent, setTitleContent] = useState<string>("");

  const handleEditTitle = (reference: Patent) => {
    if (reference) {
      setEditingTitleRefId(reference.id);
      setTitleContent(he.decode(reference.title || reference.name || ""));
    }
  };

  const handleSaveTitle = async (reference: Patent, newTitle: string) => {
    await onSaveName?.(reference, newTitle, false);
    setEditingTitleRefId("");
    setTitleContent("");
  };

  const columns: CustomColumnDef<Patent, any>[] = [
    ...(enableRowActions && isReference && checkboxStart ? [checkboxColumn] : []),

    // Move the status column here (as per previous modification)
    ...(showReferenceEditCols
      ? [
          {
            id: "status",
            accessorKey: "status",
            header: () => (
              <div className="flex items-center gap-2">
                Status
                <InfoPopover content={statusTooltipContent} />
              </div>
            ),
            cell: ({ row }) => {
              const reference = row.original;
              let statusColor = "";
              let statusText = "";
              let tooltipText = "";
              switch (reference.status) {
                case DocumentStatus.PROCESSED:
                  statusColor = "bg-green-500";
                  statusText = "Processed";
                  tooltipText = "This document has been processed";
                  break;
                case DocumentStatus.REPROCESS:
                case DocumentStatus.RECHART:
                  statusColor = "bg-yellow-500";
                  statusText =
                    reference.status === DocumentStatus.REPROCESS
                      ? "Reprocess"
                      : "Rechart";
                  tooltipText =
                    reference.status === DocumentStatus.REPROCESS
                      ? "Our algorithm has been updated since this document was processed. Reprocess for updated content."
                      : "Context has been updated since this document was charted.";
                  break;
                case DocumentStatus.REUPLOAD:
                  statusColor = "bg-red-500";
                  statusText = "Reupload";
                  tooltipText = "Delete this reference and reupload the file";
                  break;
                case DocumentStatus.PROCESSING:
                  statusColor = "bg-blue-500";
                  statusText = "Processing";
                  // tooltipText = "This document is pending processing";
                  break;
                default:
                  statusColor = "bg-gray-500";
                  statusText = "—";
                  tooltipText = "Unknown status";
              }

              return (
                <Tooltip>
                  <TooltipTrigger>
                    <Badge variant="outline" className="flex items-center gap-2">
                      {reference.status === DocumentStatus.PROCESSING ? (
                        <Spinner className="h-3 w-3" />
                      ) : (
                        <div className={`w-2 h-2 rounded-full ${statusColor}`} />
                      )}
                      {statusText}
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent>{tooltipText}</TooltipContent>
                </Tooltip>
              );
            },
          },
        ]
      : []),

    // Add the subjects column here, before the nickname column
    ...(showSubjects
      ? [
          {
            id: "subjects",
            accessorKey: "subjects",
            header: "Subjects",
            cell: ({ row }) => {
              const reference = row.original;
              return (
                <>
                  {reference.subjects?.length === currentPortfolio.projects?.length ? (
                    <Badge variant="outline" className="mr-1 mb-1 cursor-pointer">
                      All
                    </Badge>
                  ) : (
                    reference.subjects?.map((subject) => (
                      <Badge
                        key={subject.projectId}
                        variant="outline"
                        className="mr-1 mb-1 cursor-pointer whitespace-nowrap"
                        onClick={() => onSelectProject(subject)}
                      >
                        {subject.projectName}
                      </Badge>
                    ))
                  )}
                </>
              );
            },
          },
        ]
      : []),

    ...(showName
      ? [
          {
            id: "nickname",
            accessorKey: "nickname",
            header: "Name",
            sortingFn: nicknameSort,
            enableSorting: true,
            cell: ({ row }) => {
              const reference = row.original;
              return (
                <>
                  {enableRename ? (
                    <div className="w-full min-w-[6rem]">
                      {" "}
                      {/* Increased minimum width */}
                      {editingNameRefId === reference.id ? (
                        <Input
                          value={newName}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setNewName(e.target.value)
                          }
                          onBlur={() => handleSaveName(reference, newName, true)}
                          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === "Enter")
                              handleSaveName(reference, newName, true);
                          }}
                          autoFocus
                        />
                      ) : (
                        <span
                          onClick={() => isReference && handleEditName(reference)}
                          className={isReference ? "cursor-pointer" : ""}
                        >
                          {isReference
                            ? documentsToNicknames[reference.id] ||
                              reference.nickname ||
                              reference.name ||
                              "—"
                            : reference.nickname || reference.name || "—"}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className="min-w-[12rem]">
                      {" "}
                      {/* Increased minimum width */}
                      {/* {!isPortfolio
                        ? documentsToNicknames[reference.id]
                        : reference.nickname || reference.name || "—"} */}
                      {isReference
                        ? documentsToNicknames[reference.id] ||
                          reference.nickname ||
                          reference.name ||
                          "—"
                        : reference.nickname || reference.name || "—"}
                    </div>
                  )}
                </>
              );
            },
          },
        ]
      : []),

    ...(showReferenceEditCols
      ? [
          {
            id: "tags",
            accessorKey: "tags",
            header: "Tags",
            cell: ({ row }) => {
              const reference = row.original;
              const tags = Array.isArray(reference.tags) ? reference.tags : [];

              return (
                <TableTags
                  tagOptions={allTags}
                  selectedTags={tags}
                  setSelectedTags={(newTags) => {
                    setSelectedTags(newTags);
                  }}
                  onClose={() => {
                    onTagUpdate([reference], selectedTags);
                  }}
                  onApply={(newTags) => {
                    onTagUpdate([reference], newTags);
                  }}
                >
                  <div className="w-full h-full">
                    {tags.length > 0 ? (
                      <div className="flex flex-wrap gap-1">
                        {tags.map((tag) => (
                          <Badge
                            variant="outline"
                            key={tag}
                            className="whitespace-nowrap"
                          >
                            {tag}
                          </Badge>
                        ))}
                      </div>
                    ) : (
                      <div className="w-full h-full items-center justify-center">—</div>
                    )}
                  </div>
                </TableTags>
              );
            },
          },
        ]
      : []),
    {
      id: "number",
      accessorKey: "number",
      header: "Number",
      cell: ({ row }) => {
        const reference = row.original;
        return reference.number && reference.number.length < 20 ? (
          <PatentSourceNumberButton
            sourceNumber={reference.number}
            prefix={reference.prefix || ""}
          />
        ) : (
          "—"
        );
      },
    },

    {
      id: "title",
      accessorKey: "title",
      header: "Title",
      sortingFn: multiLingualSort,
      cell: ({ row }) => {
        const reference = row.original;
        return (
          <div
            onClick={() => {
              if (isReference && reference.type !== DocumentType.PATENT) {
                handleEditTitle(reference);
              } else {
                onRowClick?.(reference);
              }
            }}
            className="w-96 max-w-xl overflow-hidden text-ellipsis"
          >
            {editingTitleRefId === reference.id ? (
              <Input
                value={titleContent}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setTitleContent(e.target.value)
                }
                onBlur={() => handleSaveTitle(reference, titleContent)}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") handleSaveTitle(reference, titleContent);
                }}
                className="w-full"
                autoFocus
              />
            ) : (
              <span title={he.decode(reference.title || reference.name || "—")}>
                {he.decode(reference.title || reference.name || "—")}
              </span>
            )}
          </div>
        );
      },
      enableSorting: true,
    },
    {
      id: "uploadedAt",
      accessorKey: "uploadedAt",
      header: "Uploaded",
      enableSorting: true,
      cell: ({ row }) => {
        const reference = row.original;
        return reference.uploadedAt
          ? DateTime.fromISO(reference.uploadedAt, { zone: "utc" }).toFormat(
              "MM/dd/yyyy",
            )
          : "—";
      },
    },
    {
      id: "publicationDate",
      accessorKey: "publicationDate",
      header: "Publication",
      enableSorting: true,
      cell: ({ row }) => {
        const reference = row.original;
        return reference.publicationDate &&
          reference.publicationDate !== reference.uploadedAt
          ? DateTime.fromISO(reference.publicationDate, {
              zone: "utc",
            }).toFormat("MM/dd/yyyy")
          : "—";
      },
    },
    {
      id: "priorityDate",
      accessorKey: "priorityDate",
      header: "Priority",
      enableSorting: true,
      hidden: false,
      cell: ({ row }) => {
        const reference = row.original;
        return reference.priorityDate || reference.filingDate
          ? DateTime.fromISO(reference.priorityDate || reference.filingDate, {
              zone: "utc",
            }).toFormat("MM/dd/yyyy")
          : "—";
      },
      sortingFn: (rowA, rowB) => {
        const dateA = rowA.original.priorityDate || rowA.original.filingDate || "";
        const dateB = rowB.original.priorityDate || rowB.original.filingDate || "";
        return dateA.localeCompare(dateB);
      },
    },
    {
      id: "inventors",
      accessorKey: "inventors",
      header: "Inventors",
      cell: ({ row }) => {
        const inventors = row.original.inventors;
        if (!Array.isArray(inventors) || inventors.length === 0) {
          return "—";
        }
        return (
          <Tooltip>
            <TooltipTrigger className="text-left">
              {inventors[0]}
              {inventors.length > 1 && `, +${inventors.length - 1}`}
            </TooltipTrigger>
            <TooltipContent>{inventors.join(", ")}</TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      id: "assignee",
      accessorKey: "assignee",
      header: "Assignee",
      cell: ({ row }) => row.original.assignee || "—",
    },
    {
      id: "type",
      accessorKey: "type",
      header: "Type",
      cell: ({ row }) => {
        const reference = row.original;
        return reference.type === DocumentType.PATENT ? "Patent" : "Non-Patent";
      },
      enableHiding: true,
      enableSorting: !isSearch,
      hidden: true,
    },

    {
      id: "prefix",
      accessorKey: "prefix",
      header: "Country",
      cell: ({ row }) => {
        const reference = row.original;
        return reference.prefix || "—";
      },
      enableHiding: true,
      enableSorting: true,
      hidden: true,
    },
  ];

  if (showReferenceEditCols) {
    columns.push({
      id: "note",
      accessorKey: "note",
      header: "Notes",
      enableSorting: true,
      sortingFn: multiLingualSort,
      cell: ({ row }) => {
        const reference = row.original;
        return (
          <div onClick={() => handleEditNotes(reference)}>
            {editingNoteRefId === reference.id ? (
              <Input
                value={noteContent}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNoteContent(e.target.value)
                }
                onBlur={() => handleSaveNote(reference, noteContent)}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") handleSaveNote(reference, noteContent);
                }}
                className="w-full"
                autoFocus
              />
            ) : (
              <span>{reference.note || "—"}</span>
            )}
          </div>
        );
      },
    });
  }

  if (!checkboxStart) {
    columns.push(checkboxColumn);
  }

  if (enableRowActions) {
    columns.push({
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const reference = row.original;
        return (
          <div className="clickable-element" onClick={(e) => e.stopPropagation()}>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => handleViewDetails(reference)}>
                  View details
                </DropdownMenuItem>
                {!location.pathname.includes("/explore") && (
                  <DropdownMenuItem onClick={() => handleEditName(reference)}>
                    Rename
                  </DropdownMenuItem>
                )}
                {showReferenceEditCols && (
                  <DropdownMenuItem onClick={() => handleEditNotes(reference)}>
                    Edit notes
                  </DropdownMenuItem>
                )}
                {handleRemoveRow && (
                  <DropdownMenuItem
                    onClick={() => handleRemoveRow(reference)}
                    disabled={isReferenceDisabled}
                  >
                    Remove
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    });
  }

  const filters: Filters = {
    prefix: {
      label: "Country",
      values: Array.from(
        new Set(references?.map((ref) => ref.prefix).filter(Boolean)),
      ).map((prefix) => ({
        label: prefix,
        value: prefix,
      })),
    },

    ...(isSearch || !isReference
      ? {}
      : {
          tags: {
            label: "Tags",
            values: references
              .flatMap((ref) => ref.tags || [])
              .filter(Boolean)
              .map((tag) => ({ label: tag, value: tag })),
          },
          type: {
            label: "Type",
            values: [
              { label: "Patent", value: DocumentType.PATENT },
              { label: "Non-Patent", value: DocumentType.REFERENCE },
            ],
          },
        }),
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={references}
        onRowSelection={handleRowSelection}
        height={height}
        showActions={showTableActions}
        onDeleteSelected={onDeleteSelected}
        initialFilters={filters}
        enableRowDelete={enableRowDelete}
        onTagApply={onTagUpdate}
        showTag={true}
        showReprocess={true}
        showRechart={true}
        isModal={isSearch}
        isDeleteSelectedLoading={isDeleteSelectedLoading}
        onReprocessSelected={onReprocessSelected}
        onRowClick={onRowClick}
        onDismissSelected={onDismissSelected}
      >
        {children}
      </DataTable>
      {showPreview && (
        <DocumentViewer
          open={showPreview}
          handleClose={handleClosePreview}
          referenceId={patentDetails.id}
          citationText={""}
          startInChartMode={false}
          nickname={patentDetails.nickname}
        />
      )}
    </>
  );
};

export default DocumentsTable;
