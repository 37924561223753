/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage, UploadFilesModal } from "@/components";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { H4 } from "@/components/ui/typography";
import { ProcessReferencesModal } from "@/features/project/components/ProcessReferencesModal";
import { usePortfolio, useProject, useReferences, useViz } from "@/hooks";
import useProcess from "@/hooks/useProcess";
import { useAppStateStore, useProjectStore } from "@/store";
import { DocumentStatus, Patent } from "@/types";
import { PlusIcon } from "@radix-ui/react-icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DismissDocumentStatusModal } from "../components";
import AddReferenceByNumberModal from "../references/components/AddReferenceByNumberModal";
import DocumentsTable from "../references/components/DocumentsTable";

const PortfolioReferencesPage: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    currentPortfolio,
    updateCurrentPortfolio,
    currentPortfolioId,
    updateCurrentProjectId,
    updateCurrentProject,
  } = useProjectStore();
  const {
    updateProjectReferenceNickname,
    updateProjectReferenceNote,
    getPortfolioReferences,
    updateDocumentStatuses,
    updateReferenceTitle,
  } = useViz();
  const { reprocessDocuments } = useProcess();
  const { deleteReferences } = useReferences();
  const { getProjectMetadata } = useProject();
  const { updateIsLoading } = useAppStateStore();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAddReferencesModal, setShowAddReferencesModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showProcessReferencesModal, setShowProcessReferencesModal] = useState(false);
  const [selectedReferencesToReprocess, setSelectedReferencesToReprocess] = useState<
    Patent[]
  >([]);
  const [showDismissStatusesModal, setShowDismissStatusesModal] = useState(false);
  const [selectedReferencesToDismiss, setSelectedReferencesToDismiss] = useState<
    Patent[]
  >([]);
  const [selectedReferences, setSelectedReferences] = useState<Patent[]>([]);
  const [isReprocess, setIsReprocess] = useState<boolean>(false);
  const { updateReferencesTagsInPortfolio } = usePortfolio();
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();
  const pageName = currentPortfolio.name
    ? `${currentPortfolio.name} - Portfolio - &AI`
    : "Portfolio - &AI";

  // Updated to fetch on each page load
  useEffect(() => {
    const fetchPortfolioReferences = async () => {
      setIsLoading(true);
      const response = await getPortfolioReferences(currentPortfolioId);
      if (!response.success) {
        addErrorMessage(response.message || "Failed to fetch references");
      }
      setIsLoading(false);
    };

    if (currentPortfolioId) {
      fetchPortfolioReferences();
    }
  }, [currentPortfolioId]);

  const handleTagUpdate = useCallback(
    async (selectedRows: Patent[], selectedTags: string[]) => {
      if (!selectedTags) return;

      const referenceIds = selectedRows.map((row) => row.id);
      const response = await updateReferencesTagsInPortfolio(
        referenceIds,
        selectedTags,
      );

      if (!response.success) {
        addErrorMessage(
          response.message || "Failed to update tags for selected references.",
        );
      } else {
        addSuccessMessage("Tags updated successfully.");
      }
    },
    [currentPortfolio, updateReferencesTagsInPortfolio],
  );

  const handleDeleteSelected = useCallback(
    async (selectedReferences: Patent[]) => {
      const referenceIds = selectedReferences.map((reference) => reference.id);
      await deleteReferences(null, currentPortfolioId, referenceIds, true);
    },
    [currentPortfolioId, deleteReferences],
  );

  const updateNickname = async (reference: Patent, newName: string) => {
    try {
      const renamePromises = reference.subjects.map(async (projectReference) => {
        const response = await updateProjectReferenceNickname(
          projectReference.projectId,
          reference.id,
          newName,
        );
        if (!response.success) {
          throw new Error(
            response.message || "An error occurred while saving the name.",
          );
        }
      });

      await Promise.all(renamePromises);

      const nicknames = { ...currentPortfolio.documentsToNicknames };
      nicknames[reference.id] = newName;

      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.map((ref) =>
          ref.id === reference.id ? { ...ref, name: newName } : ref,
        ),
        documentsToNicknames: nicknames,
      });
    } catch (error) {
      addErrorMessage(error.message || "An error occurred while saving the name.");
    }
  };

  const updateTitle = async (reference: Patent, newName: string) => {
    await updateReferenceTitle(reference.id, newName);
  };

  const handleSaveName = useCallback(
    async (reference: Patent, newName: string, isNickname: boolean) => {
      if (isNickname) {
        await updateNickname(reference, newName);
      } else {
        await updateTitle(reference, newName);
      }
    },
    [currentPortfolio, updateProjectReferenceNickname],
  );

  const handleSaveNote = useCallback(
    async (reference: Patent, newNote: string) => {
      try {
        const renamePromises = reference.subjects.map(async (projectReference) => {
          const response = await updateProjectReferenceNote(
            projectReference.projectId,
            reference.id,
            newNote,
          );
          if (!response.success) {
            throw new Error(
              response.message || "An error occurred while saving the note.",
            );
          }
        });

        await Promise.all(renamePromises);

        updateCurrentPortfolio({
          ...currentPortfolio,
          references: currentPortfolio.references.map((ref) =>
            ref.id === reference.id ? { ...ref, note: newNote } : ref,
          ),
        });
      } catch (error) {
        addErrorMessage(error.message || "An error occurred while saving the name.");
      }
    },
    [currentPortfolio, updateProjectReferenceNote, updateCurrentPortfolio],
  );

  const handleDeleteReference = useCallback(
    async (reference: Patent) => {
      await deleteReferences(null, currentPortfolioId, [reference.id], true);
    },
    [currentPortfolioId, deleteReferences],
  );

  const handleDismissReferenceStatuses = useCallback(async () => {
    const selectedIds = selectedReferencesToDismiss.map((reference) => reference.id);
    await updateDocumentStatuses(selectedIds, DocumentStatus.PROCESSED, true);
    setSelectedReferencesToReprocess([]);
    setSelectedReferencesToDismiss([]);
    setShowDismissStatusesModal(false);
  }, [selectedReferencesToDismiss]);

  const handleReprocessReferences = useCallback(async () => {
    setShowProcessReferencesModal(false);
    await reprocessDocuments(selectedReferencesToReprocess, isReprocess);
    setSelectedReferencesToReprocess([]);
  }, [selectedReferencesToReprocess, isReprocess]);

  const handleReprocessClick = useCallback(
    (selectedRows: Patent[], isReprocess: boolean) => {
      setSelectedReferencesToReprocess(selectedRows);
      setIsReprocess(isReprocess);
      setShowProcessReferencesModal(true);
    },
    [],
  );

  const handleSelectProject = useCallback(
    async (reference: Patent) => {
      try {
        const id = reference.projectId;
        updateCurrentProject({
          id: id,
          name: reference.name,
        });
        updateCurrentProjectId(id);
        navigate(`/portfolio/${currentPortfolioId}/project/${id}/subject`);
        updateIsLoading(id);
        await getProjectMetadata(id);
        updateIsLoading(null);
      } catch (error) {
        console.error("Error selecting project:", error);
        addErrorMessage("Failed to fetch data. Please try again.");
      }
    },
    [updateCurrentProject, currentPortfolioId],
  );

  const dismissableReferences = useMemo(
    () =>
      selectedReferences.filter(
        (ref) =>
          ref.status === DocumentStatus.RECHART ||
          ref.status === DocumentStatus.REPROCESS,
      ),
    [selectedReferences],
  );

  const handleDismissSelected = useCallback(() => {
    setSelectedReferencesToDismiss(dismissableReferences);
    setShowDismissStatusesModal(true);
  }, [dismissableReferences]);

  const selectedReprocessDocumentNames = useMemo(
    () => selectedReferencesToReprocess.map((reference) => reference.name),
    [selectedReferencesToReprocess],
  );

  const selectedDismissDocumentNames = useMemo(
    () => selectedReferencesToDismiss.map((reference) => reference.name),
    [selectedReferencesToDismiss],
  );

  const handleRowSelection = useCallback((selectedRows: Patent[]) => {
    setSelectedReferences(selectedRows);
  }, []);

  const documentTableProps = useMemo(
    () => ({
      references: currentPortfolio.references,
      onDeleteRow: handleDeleteReference,
      height: "calc(100vh - 100px)",
      onRowSelection: handleRowSelection,
      onDeleteSelected: handleDeleteSelected,
      enableRowDelete: true,
      onReprocessSelected: handleReprocessClick,
      onDismissSelected: handleDismissSelected,
      isDeleteSelectedLoading: false,
      onTagUpdate: handleTagUpdate,
      onSaveName: handleSaveName,
      onSaveNote: handleSaveNote,
      isPortfolio: true,
      isReference: true,
      isSearch: false,
      onSelectProject: handleSelectProject,
    }),
    [
      currentPortfolio.references,
      handleDeleteReference,
      handleRowSelection,
      handleDeleteSelected,
      handleReprocessClick,
      handleDismissSelected,
      handleTagUpdate,
      handleSaveName,
      handleSaveNote,
      handleSelectProject,
    ],
  );

  return (
    <ProjectPage pageName={pageName}>
      {isLoading ? (
        <div className="flex justify-center items-center h-[calc(100vh-100px)]">
          <Loader />
        </div>
      ) : currentPortfolio.references && currentPortfolio.references.length > 0 ? (
        <DocumentsTable {...documentTableProps}>
          <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button variant="default" onClick={() => setDropdownOpen(true)}>
                Add References
                <PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  setShowAddReferencesModal(true);
                  setDropdownOpen(false);
                }}
              >
                Add Published Patents/Applications
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  setShowUploadModal(true);
                  setDropdownOpen(false);
                }}
              >
                Add Other Documents
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </DocumentsTable>
      ) : (
        <div className="flex justify-center items-center flex-col mt-12">
          <H4>This portfolio has no references.</H4>

          <div className="flex flex-row gap-2">
            <Button
              className="mt-3"
              variant="outline"
              onClick={() => setShowAddReferencesModal(true)}
            >
              Add Published Patents/Applications
            </Button>
            <Button
              className="mt-3"
              variant="outline"
              onClick={() => setShowUploadModal(true)}
            >
              Upload Other Documents
            </Button>
          </div>
        </div>
      )}

      {/* Modals */}
      <AddReferenceByNumberModal
        open={showAddReferencesModal}
        handleClose={() => setShowAddReferencesModal(false)}
        isPortfolio={true}
      />
      <UploadFilesModal
        open={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
        isPortfolio={true}
      />
      <ProcessReferencesModal
        isOpen={showProcessReferencesModal}
        onOpenChange={setShowProcessReferencesModal}
        onConfirm={handleReprocessReferences}
        documentNames={selectedReprocessDocumentNames}
        isReprocess={isReprocess}
      />
      <DismissDocumentStatusModal
        isOpen={showDismissStatusesModal}
        onOpenChange={setShowDismissStatusesModal}
        onConfirm={handleDismissReferenceStatuses}
        documentNames={selectedDismissDocumentNames}
      />
    </ProjectPage>
  );
};

export default PortfolioReferencesPage;
