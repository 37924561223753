import { Loader } from "@/components";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Spinner } from "@/components/ui/spinner";
import { useViz } from "@/hooks";
import { useCreateProjectStore } from "@/store";
import { Patent, ProcessType } from "@/types";
import {
  isLikelyUSEarlyPublicationNumber,
  isLikelyUSPatentApplicationNumber,
} from "@/utils/dataUtils";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import PatentDetailsTable from "./PatentDetailsTable";

interface PublishedApplicationNumberInputProps {
  isChecked: boolean;
  textInput: string;
  handleCheckboxState: (checkedState: boolean) => void;
  handleTextInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * @description Published application number input section
 */
const PublishedApplicationNumberInput: React.FC<
  PublishedApplicationNumberInputProps
> = ({ isChecked, textInput, handleCheckboxState, handleTextInputChange }) => {
  // Global and local states
  const {
    processes,
    subjectDetails,
    extractedApplicationDetails,
    updateSubjectDetails,
    updateSubjectNumbers,
  } = useCreateProjectStore();
  const { getPatentApplicationDetails } = useViz();
  const [inputError, setInputError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patentFound, setPatentFound] = useState<boolean>(false);

  useEffect(() => {
    setPatentFound(subjectDetails.length > 0);
  }, [subjectDetails]);

  // OA processes
  const isOfficeActionPending = useMemo(() => {
    return processes.some(
      (process) =>
        process.type === ProcessType.ADD_OFFICE_ACTION && process.status === "pending",
    );
  }, [processes]);

  const isOfficeActionParsed = useMemo(() => {
    return processes.some(
      (process) =>
        process.type === ProcessType.ADD_OFFICE_ACTION &&
        process.status === "completed",
    );
  }, [processes]);

  // Checkbox handling
  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    handleCheckboxState(newCheckedState);
    if (newCheckedState && extractedApplicationDetails.applicationNumber) {
      handleTextInputChange({
        target: { value: extractedApplicationDetails.applicationNumber },
      } as ChangeEvent<HTMLInputElement>);
    }
  };

  useEffect(() => {
    // Uncheck the checkbox if the text input doesn't match expectedApplicationNumber
    if (isChecked && textInput !== extractedApplicationDetails.applicationNumber) {
      handleCheckboxState(false);
    }
  }, [textInput, extractedApplicationDetails, isChecked, handleCheckboxState]);

  // Find application button
  const handleFindApplication = async () => {
    if (
      !isLikelyUSPatentApplicationNumber(textInput) &&
      !isLikelyUSEarlyPublicationNumber(textInput)
    ) {
      setInputError(
        "The input is not a valid US patent application number or US early publication number.",
      );
      return;
    }
    setInputError("");
    setIsLoading(true);
    try {
      const response = await getPatentApplicationDetails(textInput);
      if (!response.success || !response.data) {
        throw new Error();
      }
      updateSubjectDetails([response.data as Patent]);
      updateSubjectNumbers([response.data["number"].replace(/[\/,]/g, "")]);
    } catch {
      setInputError("Failed to find a published US patent application.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader message="Finding application and extracting details..." />
      ) : (
        <>
          <div className="space-y-2">
            {!patentFound && (
              <>
                {isOfficeActionPending && (
                  <div className="flex items-center space-x-2 text-sm text-gray-600">
                    <Spinner size="small" />
                    <span>Populating application number from office action</span>
                  </div>
                )}

                {isOfficeActionParsed && (
                  <CheckboxWithText
                    id="autopopulate"
                    checked={isChecked}
                    onCheckedChange={handleCheckboxChange}
                    label={`Use application number from office action: ${extractedApplicationDetails.applicationNumber}`}
                  />
                )}

                {
                  <>
                    <Input
                      id="application-number"
                      placeholder="Enter application number or early publication date"
                      value={textInput}
                      onChange={handleTextInputChange}
                    />
                    {inputError && (
                      <Alert variant="destructive">
                        <AlertDescription>{inputError}</AlertDescription>
                      </Alert>
                    )}
                    <Button
                      onClick={handleFindApplication}
                      className="mt-4 w-full"
                      disabled={textInput === ""}
                    >
                      Find application
                    </Button>
                  </>
                }
              </>
            )}

            {patentFound && (
              <div className="mt-4">
                <PatentDetailsTable details={subjectDetails} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PublishedApplicationNumberInput;
