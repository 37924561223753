/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DataTableCell, DataTableRowOutline } from "@/components/ui/table";
import { parseHtml } from "@/utils/parseHtml";
import React from "react";
import InvalidityTableCitationCell from "./InvalidityTableCitationCell";

// Define the types for the props
interface InvalidityTableRowProps {
  row: Record<string, any>;
  rowIndex: number;
  fetchReferenceChartData: () => void;
  setAddingCitationKey: (key: string) => void;
  addingCitationKey: string;
  setIsEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  rowVisibility: string;
}

/**
 * @description Data table row
 * @param {Record<string, any>} row - The row to display.
 * @param {number} rowIndex - The index of the row.
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 * @param {function} setAddingCitationKey - Function to set the adding citation key.
 * @param {string} addingCitationKey - The adding citation key.
 * @param {function} setIsEditing - Function to set the editing state.
 * @param {boolean} isEditing - Whether the row is editing.
 */
const InvalidityTableRow: React.FC<InvalidityTableRowProps> = ({
  row,
  rowIndex,
  fetchReferenceChartData,
  setAddingCitationKey,
  addingCitationKey,
  setIsEditing,
  isEditing,
  rowVisibility,
}) => {
  const hasAnyCitations = () => {
    return Object.keys(row)
      .filter(
        (key) =>
          key !== "claim_number" && key !== "claim_text" && key !== "invalidity_id",
      )
      .some((refId) => row[refId]?.citations?.length > 0);
  };

  return (
    <>
      <DataTableRowOutline
        key={rowIndex}
        className={`${
          !hasAnyCitations() && rowVisibility === "collapse" ? "h-[40px]" : ""
        }`}
        style={{ position: "relative" }}
      >
        <DataTableCell key={`${rowIndex}-claim_number`}>
          <span>[{row.claim_number}]</span>
        </DataTableCell>
        <DataTableCell
          key={`${rowIndex}-claim_text`}
          className={`align-top max-w-[400px] ${
            !hasAnyCitations() && rowVisibility === "collapse" ? "truncate" : ""
          }`}
        >
          <div
            className={`overflow-x-auto ${
              !hasAnyCitations() && rowVisibility === "collapse" ? "truncate" : ""
            }`}
          >
            <span>{parseHtml(row.claim_text)}</span>
          </div>
        </DataTableCell>

        {Object.keys(row)
          .filter(
            (key) =>
              key !== "claim_number" && key !== "claim_text" && key !== "invalidity_id",
          )
          .map((refId) => (
            <InvalidityTableCitationCell
              key={`${rowIndex}-${refId}`}
              index={rowIndex}
              refId={refId}
              citations={row[refId].citations}
              claimNumber={row.claim_number}
              claimText={row.claim_text}
              fetchReferenceChartData={fetchReferenceChartData}
              addingCitationKey={addingCitationKey}
              setAddingCitationKey={setAddingCitationKey}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              header={row[refId]?.header ?? ""}
              footer={row[refId]?.footer ?? ""}
              invalidityId={row[refId].invalidityId}
            />
          ))}
      </DataTableRowOutline>
    </>
  );
};

export default InvalidityTableRow;
